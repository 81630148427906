import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#191514';

const BayPage = ({ data }) => (
  <>
    <SEO
      title="777Bay Neosurf Code bonus – 133% bonus et 133 tours gratuits"
      description="Exclusif 777Bay Casino Code bonus pour l’option de paiement de Neosurf. Réclamer 133% de prime de premier dépôt et 133 tours gratuits en utilisant un code de neosurf spécial."
      alternateLanguagePages={[
        {
          path: 'casino/777bay',
          language: 'en'
        },
        {
          path: 'casino/777bay',
          language: 'fr'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="http://www.777bay.com/?a_aid=5a6fb263dabe6" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Casino 777Bay" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller textColorLight">Méthodes de paiement Neosurf &amp; Myneosurf au 777Bay casino &amp; Pari Sportif</h1>
            <p className="textColorLight">Le 777Bay.com offre une large gamme de méthodes de paiement, y compris des options de carte standard, des crypto-monnaies, des portefeuilles numériques et nos préférés : Solutions de Neosurf et de Myneosurf. Les options de dépôt rapides, sûres et faciles ne sont que de petits fragments de fonctionnalités de 777Bay. Consulte un avis complet de 777bay Casino et n’oublie pas de réclamer un code de bonus de neosurf exclusif ci-dessous pour ton premier dépôt!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Exclusif 777Bay Neosurf BONUS CODE</h2>
            <ul className="textColorDark">
              <li>133% de prime de premier dépôt et 133 Tours Gratuits</li>
              <li>UTILISE LE CODE : NEO133</li>
              <li>Dépôt minimum est : 20 € / $</li>
              <li>Exigences de pari : 30x (dépôt + bonus)</li>
              <li>Pari maximum : 7,5 € / $</li>
              <li><a href="https://www.777bay.com/terms-and-conditions/">777Bay Terms &amp; Conditions</a></li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="http://www.777bay.com/?a_aid=5a6fb263dabe6" target="_blank" rel="noopener noreferrer">OUVRIR CASINO 777 BAY</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Exclusif 777Bay Neosurf Code bonus" />
        </div>
      </div>
    </div>
  </>
)

export default BayPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777bay/777bay-accepts-Neosurf-deposit.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777bay/777bay-logo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
